import { Button } from "@mantine/core";

import Icon       from "_components/atoms/icon";
import Typography from "_components/atoms/typography";

import useStyles from "./styles";

const BottomStickyButton = ( { title, customStyle, handleDownloadClick, from } ) => {
	const { classes } = useStyles ( { from }, { name: "styles" } );

	return (
		<div
			className = { classes.bottomStickyBtnContainer }
			style     = { customStyle }
		>
			<Button
				classNames = { { root: classes.bottomStickyButton } }
				fullWidth  = { true }
				leftIcon   = { (
					<Icon
						sameInBothTheme
						// className = { classes.mobileSvg }
						name = "mobile"
						size = { 20 }
					/>
				) }
				onClick    = { () => handleDownloadClick () }
				p          = "sm"
				radius     = "lg"
			>
				<Typography
					className = { classes.innerTxt }
					title     = { title }
					weight    = "500"
				/>

			</Button>
		</div>
	);
};

export default BottomStickyButton;
