import { createStyles } from "@mantine/emotion";

export default createStyles ( ( theme, props ) => ( {
	bottomStickyBtnContainer: {
		display         : "flex",
		justifyContent  : "space-around",
		alignItems      : "center",
		flexFlow        : "wrap",
		bottom          : "0",
		zIndex          : "100",
		width           : "100%",
		backgroundColor : theme.other.colors.background.primary,
		padding         : "1rem",
		position        : props?.from === "pinnedComment" ? "relative" : "fixed",
		paddingBottom   : theme.other.isApple ? "20px" : "10px"
	},
	downloadBtn: {
		flexBasis    : "100%",
		color        : "white !important",
		borderRadius : "1.4rem"
	},
	mobileSvg: {
		height      : "20px",
		width       : "20px",
		marginRight : "0.5rem"
	},
	bottomStickyButton: {
		height     : "100%",
		background : theme.other.colors.primaryBtn.default.background
	},
	innerTxt: {
		padding  : "0.2rem 0",
		color    : theme.other.paletteNew.secondaryWhite,
		fontSize : "1.6rem"
	}
} ) );
