import { useRouter } from "next/router";
import React         from "react";

import { storeEvent, openAppSingular } from "_analytics/index";
import { isPageRoute }                 from "_common/utils";
import { SNG_STICKY_LINK }             from "_config/index";
import { SNG_OPEN_IN_APP }             from "_config/index";

import Presentation from "./presentation";

const component = props => {
	const router              = useRouter ();
	const handleDownloadClick = () => {
		let label;

		if ( isPageRoute ( { page: "home", router } ) )
			label = "Open_in_app_home";

		if ( isPageRoute ( { page: "stream", router } ) )
			label = "Open_in_app_broadcast";

		storeEvent ( "Download", label );

		const streamPage        = isPageRoute ( { page: "stream", router } );
		const videoPage         = isPageRoute ( { page: "video", router } );
		const streamOrVideoPage = streamPage || videoPage;
		const dataKey           = videoPage ? "feedId" : "broadcastSession";
		const data              = {};

		if ( streamPage )
			data[dataKey] = {
				"id": Number ( router?.query?.id )
			};
		else
			data[dataKey] = Number ( router?.query?.id );

		const _p         = streamOrVideoPage ? JSON.stringify ( data ) : "Header";
		const updatedURL = streamOrVideoPage ? SNG_OPEN_IN_APP : SNG_STICKY_LINK;

		openAppSingular ( updatedURL, _p );
	};

	return (
		<Presentation
			{ ...props }
			handleDownloadClick = { handleDownloadClick }
		/>
	);
};

export default component;
